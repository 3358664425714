import { useRouter } from '@tanstack/react-router'
import { useCallback } from 'react'

/**
 * Goes back in history, as a click on the "back" button would do.
 * @returns
 */
export function useNavigateBack(): () => void {
  const { history } = useRouter()

  return useCallback(() => history.go(-1), [history])
}
